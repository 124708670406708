import React from 'react';
import LeftBanner from './LeftBanner'; // Assuming LeftBanner.js is in the same directory
import RightBanner from './RightBanner'; // Assuming RightBanner.js is in the same directory

const Banner = () => {
  return (
    <div className="w-full flex flex-col lgl:flex-row mt-16">
      {/* Left Banner */}
      <LeftBanner />

      {/* Right Banner */}
      <RightBanner />
    </div>
  );
};

export default Banner;
