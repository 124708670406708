import React from 'react'
import { FaFacebookF,  FaInstagram, FaWhatsapp } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[30%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center mt-6">
      <img
        className="object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
       
        <p className="text-lg font-normal text-gray-400">
          FullStack Developer
        </p>
        <p className="text-base text-gray-400 tracking-wide">
         
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+92318 5606622</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">jsdiv.online@Eail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4">
         
        <a href="https://web.facebook.com/?_rdc=1&_rdr"className="bannerIcon">
          <FaFacebookF />
            </a>
           
            <a href="https://www.instagram.com/m.saqib977?igsh=dDlyaGNjMTU0bXRz"className="bannerIcon">
            <FaInstagram />
            </a>
          <a
            href="https://wa.me/923429765955"
            target="_blank"
            rel="noopener noreferrer"
            className="bannerIcon"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft