import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2017 - 2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Fullstack Developer"
            subTitle="OGDCL Official Contractor - (2017 - Present)"
            result="PK"
            des="OGDCL Official Contractor  hiring process is an important part of our culture. Ogdcl care deeply about their teams and the people who make them up."
          />
          <ResumeCard
            title="Web Development & Themes Customization"
            subTitle="StoneValley Team - (2021 - 2023)"
            result="USA"
            des="A popular destination with a growing number of highly qualified homegrown graduates, it's true that securing a role in USA isn't easy."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Web Gurru - (2020 - 2024)"
            result="OMAN"
            des="The Oman economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2001 - 2020</p>
          <h2 className="text-3xl md:text-4xl font-bold">Trainer Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Nodejs Instructor"
            subTitle="Science Academy -(2015 - 2020)"
            result="PK"
            des="Proficient in developing robust, scalable web applications with both front-end and back-end technologies."
          />
          <ResumeCard
            title="Web Developer and Instructor"
            subTitle="Khyber inter College-(2017 - 2019)"
            result="PK"
            des="Specialize in creating dynamic, user-friendly websites using modern web development tools and frameworks."
          />
          <ResumeCard
            title="Front-end Developer"
            subTitle="Kohat Higher Secondary School (2021 - 2024)"
            result="PK"
            des="Focused on delivering visually appealing and interactive front-end experiences using React,HTML, CSS, and JavaScript."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
