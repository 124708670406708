import React from 'react';
import { FaCheck } from 'react-icons/fa';

const PackagesSection = () => {
  const packageData = [
    {
      title: 'Basic Package',
      price: '$85', 
      features: [
        '1 Responsive landing page',
        '4 Simple pages',
        'Product/Img Uploading',
        'Simple Graphic Designing',
        '1 Sites Host with Personal C-Panl',
        'Free Domain (1YEAR) ',
        'Free SSL ',
        '2GB Storage',
        '20GB Bandwidth',
        '1 Click Wordpress Installation',
      ],
      buttonText: 'Choose Design',
      demoLink: 'https://elements.envato.com/wordpress/landing+page', 
    },
    {
      title: 'Standard Package',
      price: '$130', 
      features: [
          'Themes Customization',
          'Multiple pages',
          'Product/Img Uploading',
          'Professional Graphic Designing',
          '3 Sites Host with Personal C-Panl',
          'Free Domain (1YEAR) ',
          'Free SSL ',
          '5GB SSD Web Space',
          '50GB Bandwidth',
          '1 Click Wordpress Installation',
          'Litespeed, Imunify360 & Jetbackup',
      ],
      buttonText: 'Choose Design',
      demoLink: 'https://elements.envato.com/wordpress/themes/thems', // Link to demo site
    },
    {
      title: 'Premium Package',
      price: '$200', // Add price here
      features: [
        'React Responsive landing pages',
        'Product/Img Uploading',
        'App Build / Deploy',
        'Graphic Designing',
        'Unlimited Sites Host / C-Panl',
        'Free Domain (1YEAR) ',
        'FREE Letsencrypt Auto SSL',
        '10GB SSD Web Space',
        '200GB Bandwidth',
        'Litespeed, Imunify360 & Jetbackup',
      
      ],
      buttonText: 'Choose Design',
      demoLink: 'https://elements.envato.com/web-templates/react', // Link to demo site
    },
  ];

  return (
    <section id="packages" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center mb-8">
        <h1 className="text-5xl font-bold capitalize mb-5">My Packages</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14 justify-center items-center text-center">
        {packageData.map((pkg, index) => (
          <div
            key={index}
            className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000"
          >
            <div className="w-full mt-5 flex flex-col gap-6 justify-center items-center text-center">
              <h2 className="text-2xl capitalize text-designColor font-semibold mb-2">{pkg.title}</h2>
              {/* Display the price */}
              <p className="text-lg font-medium text-white mb-4">{pkg.price}</p>
              <ul className="mb-6">
                {pkg.features.map((feature, i) => (
                  <li key={i} className="flex items-center mb-2">
                    <FaCheck className="text-designColor-500 mr-2" /> {feature}
                  </li>
                ))}
              </ul>
              <a href={pkg.demoLink} target="_blank" rel="noopener noreferrer">
                <button className="bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-700 transition-colors duration-300">
                  {pkg.buttonText}
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PackagesSection;
