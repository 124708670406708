import React, { useState, useEffect } from 'react';
import { bannerImg } from "../../assets/index";

const RightBanner = () => {
  const [showBanner, setShowBanner] = useState(window.innerWidth > 1020);

  useEffect(() => {
    const handleResize = () => {
      setShowBanner(window.innerWidth > 1020);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    showBanner ? (
      <div className="w-full lgl:w-1/2 flex justify-center items-center relative  ml-36">
        <img
          className="w-[300px] h-[400px] lgl:w-[500px] lgl:h-[510px] z-10"
          src={bannerImg}
          alt="bannerImg"
        />
        <div className="absolute bottom-0 w-[350px] h-[300px] lgl:w-[500px] lgl:h-[500px] flex justify-center items-center"></div>
      </div>
    ) : null
  );
}

export default RightBanner;